aside {

  &:not(.toc-container) {}

  .cgds.sidenav {
    padding: 1rem;
    background-color: var(--cgds-gray-100);

    a {
      text-decoration: none;
      color: var(--cgds-gray-500);

      &:hover {
        color: var(--cgds-gray-700);
      }
    }

    .sidenav-item+.sidenav-item {
      margin-top: 0;
    }

  }
}
