section.wrapper{
  &.home{
    width: initial;
    max-width: 1440px;
    section{
      padding-top: calc(#{$spacer} * 3);
      padding-bottom: calc(#{$spacer} * 3);
      @include media-breakpoint-down(lg){
        padding-top: calc(#{$spacer} * 1);
        padding-bottom: calc(#{$spacer} * 1);
      }
      &.home-hero{
        display:grid;
        grid-template-columns: 0.4fr 0.6fr;
        align-items: center;
        @include media-breakpoint-down(lg){
          grid-template-columns: 1fr;
          height: auto;
        }
        .home-hero-content{
          .image-stack.image-grid{
            height: 32.5rem;
            img{
              filter: drop-shadow(0 0 0.9rem var(--cgds-gray-300));
            }
            @include media-breakpoint-down(lg){
              margin-top: 1rem;
              height: auto;
              img{
                filter: drop-shadow(0 0 0.5rem var(--cgds-gray-300));
              }
            }

          }
        }
      }
      &.home-get-started{
        display:grid;
        grid-template-columns: 30% 1fr;
        gap:3rem;
        @include media-breakpoint-down(lg){
          grid-template-columns: 1fr;
          gap: 1rem;
        }
        .home-get-started-content{
          @media (min-width: 1200px) {
            position: sticky;
            top: 110px;
            align-self: start;
          }
        }
        .home-get-started-header{
          margin-bottom: 2rem;
        }
        .home-get-started-content-grid{
          display: grid;
          grid-template-columns: repeat(2,1fr);
          gap: 3rem;
          @include media-breakpoint-down(lg){
            grid-template-columns: 1fr;
            gap: 1rem;
          }
          .grid{
            display:grid;
            grid-template-rows: repeat(2,1fr);
            gap: 3rem;
            @include media-breakpoint-down(lg){
              gap: 1rem;
            }
            justify-content: stretch;
            &.left{
              padding-bottom: 3rem;
              @include media-breakpoint-down(lg){
                padding: 0;
              }
            }
            &.right{
              padding-top: 3rem;
              @include media-breakpoint-down(lg){
                padding: 0;
              }
            }

            .card{
              background: hsl(228, 38%, 97%);
              border: none;
              &-eyebrow{
                color: var(--cgds-primary);
                margin-bottom: 4px;
                font-weight: bold;
              }
              &-body{
                padding: 30px 50px;
                color: var(--cgds-gray-600);
                a{
                  color: var(--cgds-primary);
                  font-weight: bold;
                }
              }
            }
          }

        }
      }
      &.home-part-of-change{
        display: grid;
        grid-template-columns: 0.55fr 1fr;
        align-items: center;
        gap: 3rem;
        @include media-breakpoint-down(lg){
          grid-template-columns: 1fr;
        }
        &:nth-child(odd){
          grid-template-columns: 1fr 0.55fr;
          @include media-breakpoint-down(lg){
            grid-template-columns: 1fr;
          }
        }
        + .part-of-change{
          margin-top: 8rem;
          @include media-breakpoint-down(lg){
            margin-top: 3rem;
          }
        }
      }
    }
  }


}

.image-stack{
  display: grid;
  grid-template-columns: repeat(6, calc(100%/6));

  img{
    object-fit: fill;
    &:first-child{
      grid-row: 1;
      grid-column: 1/span 4;
      z-index: 1;
      align-self: flex-end;
    }
    &:last-child{
      grid-row: 1;
      grid-column: 2/-1;
    }
  }
}



.hero-bg{
  background: url('../img/landing-hero/bg.svg');
  background-repeat: no-repeat;
  background-position: -40rem top;
  @media screen and (min-width: 1801px){
    background-position: -20rem top;
  }
  top: 0;
  width: 1896px;
  height: 675px;
  z-index: -1;
}
