.page-component{
  .header-component{
    .header-component-description{
      @media (min-width: 1390px) {
        width: 75ch;
      }
      width: auto;

    }
    .component-source-grid{
      @media (max-width: 1405px) {
        display: block;
        div + div{
          margin-top: 0.5rem;
        }

      }

      display: flex;
      gap: 2rem;

    }
  }
}
