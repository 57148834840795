.cgds-example {
  border: 1px solid var(--cgds-gray-400);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 2rem;
  background-color: var(--cgds-gray-100);
  &:last-child {
    border-top: none;
  }
  &.grid-auto-column {
    display: grid;
    gap: 2rem;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
  @include media-breakpoint-down(sm) {
    padding: 0.25rem;
  }
}

[class*="cgds-example"] {
  margin-top: 1rem;
}

// compopnent-specific
.cgds-example {
  &.highlight {
    padding: 0;
    figure {
      margin-bottom: 0;
      pre {
        padding: 1rem 2rem;
        margin: 0;
        code {
          color: inherit;
        }
      }
    }
  }
  > .btn {
    margin: 0.25rem;
  }

  // Card specific
  .cgds.card {
    width: 20rem;
    @media (max-width: 36rem) {
      width: 100%;
    }
    &[variant="card-action-quantity-toggle"] {
      width: 30rem;
      @media (max-width: 75rem) {
        width: 100%;
      }
    }
    &[variant="card-horizontal"]{
      width: 40rem;
    }
  }

  &-card-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.cgds-example-image {
  &-container {
    overflow-x: auto;
    display: grid;
    padding: 2rem;
    background-color: var(--cgds-gray-100);
    place-items: center;
    margin-bottom: 1rem;
    border-radius: $border-radius;
  }
  &-grid {
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 1rem;
    place-items: center;
    img,
    svg {
      max-width: 100%;
      height: auto;
      background: transparent;
      padding: 0;
    }
    @include media-breakpoint-down(lg) {
      grid-auto-flow: row;
    }
  }
  &-subgrid {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    gap: 1rem;
    place-items: center;
  }
}
