@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Kantumruy Pro:wght@200;400;600;700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");


.w-75ch{
  width: 75ch;
}

body{
  color: var(--cgds-gray-600);
}

.title{
  font-weight: bold;
}

p,li{
  color: var(--cgds-gray-600)
}

h1{
  letter-spacing: -0.02em;
}

cgds-masthead{
  display: block;
}

.subtitle{
  color: var(--cgds-gray-600);
}

