.cgds {
  &.navbar {

    header {
      background-color: $navbar-bg-color !important;

      .btn-gt {
        color: $primary !important;
        background-color: $white;
        width: 85px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 14px;

        &.active {
          color: $white !important;
          font-weight: $font-weight-bold;
          @include gradient-bg($navbar-link-hover-bg);
        }

        &:hover {
          color: $white !important;
          @include gradient-bg($navbar-link-hover-bg);
        }
      }
    }

    background-color: $navbar-bg-color;
    border-bottom: 1px solid rgba(var(--cgds-body-color-rgb), 0.2);

    .navbar-nav {
      gap: 5px;
    }

    .navbar-mobile-widget {
      display: flex;
      height: 100%;
      gap: 1rem;
    }

    .navbar-toggler {
      &:focus {
        box-shadow: none;
      }
    }
  }
}
